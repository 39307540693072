import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Drawer,
  Empty,
  message,
  Pagination,
  Popconfirm,
  Space,
  Spin,
  Tag,
} from 'antd';
// import Layout from '../../../containers/Layout';
import { connect, useSelector } from 'react-redux';
import CreateEditQuestion from './createEditQuestion';
import { getArrayValues } from '../../../../utility-functions';
import ClickerQuestionFilters from './filter';
import axiosInstance from 'v2/config/axios';
import endpoints from 'v2/config/endpoints';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import Question from './question';
import moment from 'moment';

const maxBranchCount = 5;
const ClickerQuiz = () => {
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createQuestionmodalData, setCreateQuestionmodalData] = useState({
    show: false,
    isEdit: {
      show: false,
      id: null,
    },
    data: {
      quizName: '',
      quizInstructions: '',
      quizDuration: null,
      quizDateAndTime: null,
      selectedQuestionPaper: null,
      academicYear: null,
      branch: [],
      grade: null,
      subject: null,
      section: [],
      isSectionWiseDate: false,
      sectionWiseDate: null,
      dropDown: {
        branch: null,
        grade: null,
        subject: null,
        section: null,
      },
    },
    questionPaperModalData: {
      show: false,
      data: {
        allQuestionPaper: null,
        selectedQuestionPaper: null,
        loading: false,
        page: { currentPage: 1, pageSize: 15, totalData: null },
      },
    },
  });
  const [filterData, setFilterData] = useState({
    branch: null,
    grade: null,
    subject: null,
    section: [],
    loading: false,
    page: { currentPage: 1, pageSize: 15, totalData: null },
  });

  const [quizListData, setQuizListData] = useState({
    data: null,
    page: { currentPage: 1, pageSize: 15, totalData: null },
  });
  const [quizViewModalData, setQuizViewModalData] = useState({ show: false, data: null });

  //branch
  useEffect(() => {
    if (createQuestionmodalData?.show) {
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        data: { ...createQuestionmodalData?.data, academicYear: selectedAcademicYear },
      });
      getBranchList(selectedAcademicYear?.id);
    }
  }, [createQuestionmodalData?.show]);

  //grade
  useEffect(() => {
    if (createQuestionmodalData?.data?.branch) {
      getGradeList(
        selectedAcademicYear?.id,
        createQuestionmodalData?.data?.branch
          ?.map((el) => el?.optionsData?.branch?.id)
          ?.toString()
      );
    }
  }, [createQuestionmodalData?.data?.branch]);

  //subject
  useEffect(() => {
    if (createQuestionmodalData?.data?.branch && createQuestionmodalData?.data?.grade) {
      getSectionList(
        selectedAcademicYear?.id,
        createQuestionmodalData?.data?.branch
          ?.map((el) => el?.optionsData?.branch?.id)
          ?.toString(),
        createQuestionmodalData?.data?.grade?.grade_id
      );
    }
  }, [createQuestionmodalData?.data?.branch, createQuestionmodalData?.data?.grade]);
  //section
  useEffect(() => {
    if (
      createQuestionmodalData?.data?.branch &&
      createQuestionmodalData?.data?.grade &&
      createQuestionmodalData?.data?.section.length &&
      (createQuestionmodalData?.data?.dropDown?.section?.length ||
        createQuestionmodalData?.isEdit?.show)
    ) {
      getSubjectList(
        createQuestionmodalData?.data?.branch?.map((el) => el?.id)?.toString(),
        createQuestionmodalData?.data?.grade?.grade_id
      );
    }
  }, [
    createQuestionmodalData?.data?.branch,
    createQuestionmodalData?.data?.grade,
    createQuestionmodalData?.data?.section,
  ]);
  //Question Paper
  useEffect(() => {
    if (
      createQuestionmodalData?.data?.branch &&
      createQuestionmodalData?.data?.grade &&
      createQuestionmodalData?.data?.subject &&
      createQuestionmodalData?.questionPaperModalData?.show
    ) {
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        questionPaperModalData: {
          ...createQuestionmodalData?.questionPaperModalData,
          loading: true,
        },
      });
      getQuestionPaperList({
        acad_session: createQuestionmodalData?.data?.branch
          ?.map((el) => el?.id)
          ?.toString(),
        grade: createQuestionmodalData?.data?.grade?.grade_id,
        subject: createQuestionmodalData?.data?.subject?.subject_id,
        page: createQuestionmodalData?.questionPaperModalData?.data?.page?.currentPage,
      });
    }
  }, [
    createQuestionmodalData?.data?.branch,
    createQuestionmodalData?.data?.grade,
    createQuestionmodalData?.data?.subject,
    createQuestionmodalData?.questionPaperModalData?.show,
  ]);

  //GET API

  //branch
  const getBranchList = (id) => {
    axiosInstance
      .get(endpoints.academics.branches, {
        params: { session_year: id ? id : selectedAcademicYear.id },
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          setCreateQuestionmodalData({
            ...createQuestionmodalData,
            data: {
              ...createQuestionmodalData.data,
              dropDown: {
                ...createQuestionmodalData.data.dropDown,
                branch: result.data?.data?.results,
              },
            },
          });
        }
      })
      .catch((error) => {});
  };
  //grade
  const getGradeList = (academicYearId, branchId) => {
    axiosInstance
      .get(endpoints.academics.grades, {
        params: {
          session_year: academicYearId ? academicYearId : selectedAcademicYear?.id,
          branch_id: branchId
            ? branchId
            : createQuestionmodalData?.data?.branch?.branch?.id,
        },
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          let data = result?.data?.data;
          const uniqueGradesMap = new Map();
          data.forEach((item) => {
            if (!uniqueGradesMap.has(item.grade_id)) {
              uniqueGradesMap.set(item.grade_id, {
                grade_id: item.grade_id,
                grade_name: item.grade_name,
              });
            }
          });
          const uniqueGrades = Array.from(uniqueGradesMap.values());

          setCreateQuestionmodalData({
            ...createQuestionmodalData,
            data: {
              ...createQuestionmodalData.data,
              dropDown: {
                ...createQuestionmodalData.data.dropDown,
                grade: uniqueGrades,
              },
            },
          });
        }
      })
      .catch((error) => {});
  };

  //subject
  const getSubjectList = (acad_session, gradeId) => {
    axiosInstance
      .get(endpoints.academics.subjectList, {
        params: {
          acad_session: acad_session
            ? acad_session
            : createQuestionmodalData?.data?.branch[0]?.id,
          grades: gradeId ? gradeId : createQuestionmodalData?.data.grade?.grade_id,
        },
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          setCreateQuestionmodalData({
            ...createQuestionmodalData,
            data: {
              ...createQuestionmodalData.data,
              dropDown: {
                ...createQuestionmodalData?.data?.dropDown,
                subject: result.data?.result,
              },
            },
          });
        }
      })
      .catch((error) => {});
  };

  //section
  const getSectionList = (sessionYear, branchId, gradeId) => {
    axiosInstance
      .get(endpoints.academics.sections, {
        params: {
          session_year: sessionYear ? sessionYear : selectedAcademicYear.id,
          branch_id: branchId
            ? branchId
            : createQuestionmodalData?.data?.branch?.branch?.id,
          grade_id: gradeId ? gradeId : createQuestionmodalData?.data.grade?.grade_id,
        },
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          let selectedSections;
          if (createQuestionmodalData?.data?.branch?.length > maxBranchCount) {
            selectedSections = result.data?.data?.map((el) => ({
              key: el?.id,
              id: el?.id,
              value: el.id,
              optionsData: el,
              children: el?.section__section_name,
            }));
          } else {
            selectedSections = [];
          }
          setCreateQuestionmodalData({
            ...createQuestionmodalData,
            data: {
              ...createQuestionmodalData.data,
              section: selectedSections,
              dropDown: {
                ...createQuestionmodalData.data.dropDown,
                section: result.data?.data,
              },
            },
          });
        }
      })
      .catch((error) => {});
  };

  const getQuestionPaperList = (params = {}) => {
    params = {
      acad_session: params?.acad_session || createQuestionmodalData?.data?.branch?.id,
      grade: params?.grade || createQuestionmodalData?.data?.grade?.grade_id,
      subject: params?.subject || createQuestionmodalData?.data?.subject?.subject_id,
      page:
        params?.page ||
        createQuestionmodalData?.questionPaperModalData?.data?.page?.currentPage,
    };
    axiosInstance
      .get(endpoints.clicker.questionPaperList, {
        params: { ...params },
      })
      .then((response) => {
        setCreateQuestionmodalData({
          ...createQuestionmodalData,
          questionPaperModalData: {
            ...createQuestionmodalData?.questionPaperModalData,
            data: {
              ...createQuestionmodalData?.questionPaperModalData?.data,
              allQuestionPaper: response?.data?.data?.result?.results,
              selectedQuestionPaper: null,
              loading: false,
              page: {
                currentPage:
                  params?.page ||
                  createQuestionmodalData?.questionPaperModalData?.data?.page
                    ?.currentPage,
                pageSize: 10,
                totalData: response?.data?.data?.result?.count,
              },
            },
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getQuizList = (params = {}) => {
    params = {
      acad_session: params.branch ? params?.branch?.id : filterData?.branch?.id,
      grade: params?.grade ? params?.grade?.grade_id : filterData?.grade?.grade_id,
      subject: params?.subject
        ? params?.subject?.subject_id
        : filterData?.subject?.subject_id,
      section_mappings: params?.section
        ? getArrayValues(params.sections, 'id')?.join(',')
        : getArrayValues(filterData?.section, 'id')?.join(','),
      page: params?.page ? params?.page : quizListData?.page?.currentPage,
    };

    if (!params?.branch?.id && !filterData?.branch?.id) {
      message.error('Please select branch');
      return;
    }
    if (!params?.grade?.grade_id && !filterData?.grade?.grade_id) {
      message.error('Please select grade');
      return;
    }

    if (!params?.section && !filterData?.section?.length) {
      message.error('Please select section');
      return;
    }
    if (!params?.subject?.subject_id && !filterData?.subject?.subject_id) {
      message.error('Please select subject');
      return;
    }

    setFilterData({ ...filterData, loading: true });

    axiosInstance
      .get(endpoints.clicker.quizList, { params: { ...params } })
      .then((res) => {
        setQuizListData({
          ...quizListData,
          data: res.data.result.results,
          page: {
            ...quizListData?.page,
            currentPage: res.data.result.current_page,
            totalData: res.data.result.count,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFilterData({ ...filterData, loading: false });
      });
  };

  const openEditQuizModal = (quiz) => {
    setCreateQuestionmodalData({
      show: true,
      isEdit: {
        show: true,
        id: quiz?.id,
      },
      data: {
        quizName: quiz?.assessment_details?.test_details?.test_name,
        quizInstructions: quiz?.assessment_details?.test_details?.instructions,
        quizDuration: quiz?.assessment_details?.test_details?.test_duration,
        quizDateAndTime: moment(quiz?.test_date),
        selectedQuestionPaper: { questions: quiz?.assessment_details?.questions }, //
        academicYear: null,
        branch: [
          {
            key: quiz?.branch_details?.[0]?.acad_session_id,
            value: quiz?.branch_details?.[0]?.acad_session_id,
            children: quiz?.branch_details?.[0]?.branch_name,
            id: quiz?.branch_details?.[0]?.acad_session_id,
            optionsData: {
              id: quiz?.branch_details?.[0]?.acad_session_id,
              branch: {
                id: quiz?.branch_details?.[0]?.branch_id,
                branch_name: quiz?.branch_details?.[0]?.branch_name,
              },
            },
            // id: quiz?.branch_details?.[0]?.acad_session_id,
            // branch: {
            //   id: quiz?.branch_details?.[0]?.branch_id,
            //   branch_name: quiz?.branch_details?.[0]?.branch_name,
            // },
          },
        ],
        grade: {
          grade_id: quiz?.grade?.id,
          grade_name: quiz?.grade?.grade_name,
        },
        subject: {
          subject_id: quiz?.subject?.[0]?.id,
          subject__subject_name: quiz?.subject?.[0]?.subject_name,
        },
        section: [
          {
            id: quiz?.section_mapping?.[0]?.id,
            sec_name: quiz?.section_mapping?.[0]?.section_name,
          },
        ],
        isSectionWiseDate: false,
        sectionWiseDate: null,
        dropDown: {
          branch: null,
          grade: null,
          subject: null,
          section: null,
        },
      },
      questionPaperModalData: {
        show: false,
        data: {
          allQuestionPaper: null,
          selectedQuestionPaper: null,
          loading: false,
          page: { currentPage: 1, pageSize: 15, totalData: null },
        },
      },
    });
  };

  const handleDeleteQuiz = (quiz) => {
    setDeleteLoading(true);
    axiosInstance
      .delete(`${endpoints.clicker.quizList}${quiz?.id}/`)
      .then((res) => {})
      .catch((err) => message.error(err?.message))
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  const quiz = (
    <>
      <div>
        <Spin tip='Loading...' size='large' spinning={filterData?.loading}>
          {quizListData?.data?.length > 0 ? (
            <>
              {quizListData?.data?.map((eachQuiz, index) => {
                return (
                  <div
                    className='row p-2 mb-3 th-br-8 th-pointer'
                    style={{ outline: '1px solid #f3f3f3' }}
                  >
                    <div className='col-md-12 px-0 d-flex justify-content-between'>
                      <div className='pb-2'>
                        <span className='th-12'>
                          Branch :{eachQuiz?.branch_details?.[0]?.branch_name} | Grade:{' '}
                          {eachQuiz?.grade?.grade_name} | Section:{' '}
                          {eachQuiz?.section_mapping?.[0]?.section_name} | Test Duration :{' '}
                          {eachQuiz?.assessment_details?.test_details?.test_duration}
                        </span>
                      </div>
                      <div className='text-right'>
                        <Tag
                          icon={<EyeOutlined />}
                          color='green'
                          onClick={() =>
                            setQuizViewModalData({ show: true, data: eachQuiz })
                          }
                          className='th-pointer th-12'
                        >
                          View More
                        </Tag>
                        <Tag
                          icon={<EditOutlined />}
                          color='processing'
                          onClick={() => {
                            openEditQuizModal(eachQuiz);
                          }}
                          className='th-pointer th-12'
                        >
                          Edit
                        </Tag>
                        <Popconfirm
                          okButtonProps={{ loading: deleteLoading }}
                          placement='topLeft'
                          title='Are you sure you want to delete this quiz ?'
                          onConfirm={() => handleDeleteQuiz(eachQuiz)}
                        >
                          <Tag
                            icon={<DeleteOutlined />}
                            color='volcano'
                            className='th-pointer th-12'
                          >
                            Delete
                          </Tag>
                        </Popconfirm>
                      </div>
                    </div>
                    <div
                      className='col-md-12 pl-0 border-right th-bg-blue-1 th-br-8'
                      style={{ maxHeight: '200px', overflowY: 'auto' }}
                    >
                      <div className=' row p-2 w-100'>
                        <div className='th-question-box w-100'>
                          <span className='th-18 th-blue'>
                            {eachQuiz?.assessment_details?.test_details?.test_name}
                          </span>
                          <div className='mt-2 d-flex justify-content-between'>
                            <div>
                              Total Questions:{' '}
                              {eachQuiz?.assessment_details?.questions?.length} | Total
                              Marks :
                              {eachQuiz?.assessment_details?.questions.reduce(
                                (sum, question) => sum + question.question_mark,
                                0
                              )}
                            </div>
                            <div>
                              Created by {eachQuiz?.created_by?.username} at{' '}
                              {moment(eachQuiz?.created_at)?.format('DD-MM-YY HH:mm')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {quizListData?.data?.length && (
                <div className='row justify-content-center'>
                  <div className='col-12'>
                    <div className='text-center'>
                      <Pagination
                        current={quizListData?.page?.currentPage}
                        hideOnSinglePage={false}
                        showSizeChanger={false}
                        pageSize={quizListData?.page?.pageSize}
                        total={quizListData?.page?.totalData}
                        style={{ justifyContent: 'center' }}
                        onChange={(currentPage) => {
                          getQuizList({
                            page: currentPage,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              className='row justify-content-center align-items-center'
              style={{ height: 300 }}
            >
              <div className='col-12 text-center'>
                <Empty description={'No Data'} />
              </div>
            </div>
          )}
        </Spin>
      </div>
    </>
  );
  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-md-12 th-bg-white th-br-8 px-1'>
          <div className='th-br-5 '>
            <ClickerQuestionFilters
              createQuestionmodalData={createQuestionmodalData}
              setCreateQuestionmodalData={setCreateQuestionmodalData}
              filterData={filterData}
              setFilterData={setFilterData}
              getQuizList={getQuizList}
              isFilter={true}
            />
          </div>
          <div className='th-br-5 p-2'>
            {quizListData?.data ? (
              <>
                <div className=' px-1 th-br-8 th-tabs mt-0'>{quiz}</div>
              </>
            ) : (
              <div
                className='row justify-content-center align-items-center'
                style={{ height: 300 }}
              >
                <div className='col-12 text-center'>
                  <Spin
                    tip='Loading...'
                    size='large'
                    spinning={filterData.loading}
                  ></Spin>
                  {filterData.loading ? null : (
                    <Empty description={'Please apply filter to view question.'} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {createQuestionmodalData?.show && (
        <CreateEditQuestion
          createQuestionmodalData={createQuestionmodalData}
          setCreateQuestionmodalData={setCreateQuestionmodalData}
          getQuizList={getQuizList}
          maxBranchCount={maxBranchCount}
        />
      )}
      {quizViewModalData?.show && (
        <>
          <Drawer
            title={quizViewModalData?.data?.assessment_details?.test_details?.test_name}
            destroyOnClose={true}
            maskClosable={false}
            width={'60%'}
            onClose={() => setQuizViewModalData({ show: false, data: null })}
            visible={quizViewModalData?.show}
            footer={<></>}
          >
            <div
              className='row p-2 mb-3 pl-4 pr-4 justify-content-between th-br-8'
              style={{ backgroundColor: '#dee2e6' }}
            >
              <div className='d-flex flex-row'>
                <div className='th-fw-600'>
                  {quizViewModalData?.data?.grade?.grade_name}
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className='th-fw-600 mr-1'>
                  {quizViewModalData?.data?.section_mapping[0]?.section_name}
                </div>
                {/* <div></div> */}
              </div>
              <div className='d-flex flex-row'>
                <div className='th-fw-600 mr-1'>Duration:</div>
                <div>
                  {
                    quizViewModalData?.data?.assessment_details?.test_details
                      ?.test_duration
                  }{' '}
                  Min
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className='th-fw-600 mr-1'>Total Marks:</div>
                <div>
                  {' '}
                  {quizViewModalData?.data?.assessment_details?.questions.reduce(
                    (sum, question) => sum + question.question_mark,
                    0
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div
                className='col-12 mb-2 px-2 pt-2 th-br-8'
                style={{ backgroundColor: '#dee2e6' }}
              >
                <div className='th-fw-700 th-16'>Instructions</div>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {
                    quizViewModalData?.data?.assessment_details?.test_details
                      ?.instructions
                  }
                </p>
              </div>
            </div>
            <div className='mt-3'>
              {quizViewModalData?.data?.assessment_details?.questions?.map(
                (eachQuestion, index) => {
                  return (
                    <Question
                      currentQuestion={eachQuestion}
                      index={index}
                      key={index}
                      // createQuestionmodalData={createQuestionmodalData}
                      // setCreateQuestionmodalData={setCreateQuestionmodalData}
                      onlyShowQuestion={true}
                    />
                  );
                }
              )}
            </div>
          </Drawer>
        </>
      )}
    </React.Fragment>
  );
};

export default ClickerQuiz;
