import React, { useEffect, useState } from 'react';
import { Drawer, Form, Select, Button, Row, Col, Typography, message, Spin } from 'antd';
import axiosInstance from 'v2/config/axios';
import endpoints from 'config/endpoints';
import debounce from 'lodash/debounce';

const { Option } = Select;

const UpdateUserDrawer = ({
  drawerData,
  onClose,
  onUpdate,
  departmentOptions,
  cadreOptions,
  cityOptions,
}) => {
  const [form] = Form.useForm();
  const [isUpdating, setIsUpdating] = useState(false);
  const [managerOptions, setManagerOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [selectedManager, setSelectedManager] = useState({});
  const [fetchingUserDetais, setFetchingUserDetails] = useState(false);
  const getGlobalUserRecords = async (text) => {
    try {
      const result = await axiosInstance.get(
        `${endpoints.gloabSearch.getUsers}?search=${text}&page=${1}&page_size=100`
      );
      if (result.data.status_code === 200) {
        setManagerOptions(result.data.data.results);
      } else {
      }
    } catch (error) {}
  };
  const fetchManagers = debounce(async (searchText) => {
    if (!searchText) {
      setManagerOptions([]);
      return;
    }

    try {
      setFetching(true);
      await getGlobalUserRecords(searchText);
    } catch (error) {
      console.error('Error fetching managers:', error);
    } finally {
      setFetching(false);
    }
  }, 500);
  const fetchUserData = (id) => {
    setFetchingUserDetails(true);
    axiosInstance
      .get(`${endpoints.masterManagement.managerEmployeeMapping}?employee_id=${id}`)
      .then((res) => {
        if (res?.data?.status_code == 200) {
          form.setFieldsValue({
            manager: res?.data?.result?.manager_id,
            city: res?.data?.result?.employee__user__user_dept_info__city_id,
            department: res?.data?.result?.employee__user__user_dept_info__department_id,
            cadre: res?.data?.result?.employee__user__user_dept_info__cader,
          });
          setSelectedManager({
            erp_id: res?.data?.result?.manager__erp_id,
            id: res?.data?.result?.manager_id,
          });
          setManagerOptions([
            {
              erp_id: res?.data?.result?.manager__erp_id,
              id: res?.data?.result?.manager_id,
              name: res?.data?.result?.manager__name,
            },
          ]);
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFetchingUserDetails(false);
      });
  };
  const updateUserData = async (body) => {
    axiosInstance
      .put(`${endpoints.masterManagement.managerEmployeeMapping}`, body)
      .then((res) => {
        console.log(res);
        form.resetFields();
        onUpdate();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const handleUpdate = () => {
    setIsUpdating(true);
    form
      .validateFields()
      .then(async (values) => {
        let body = {
          employee_id: drawerData?.data?.id,
          employee_user_id: drawerData?.data?.user?.id,
          manager_erp_id: selectedManager?.erp_id,
          manager_id: values?.manager,
          employee_erp_id: drawerData?.data?.erp_id,
          cader: values?.cadre,
          city: values?.city,
          department: values?.department,
        };
        await updateUserData(body);
      })
      .catch((error) => {
        console.error('Validation failed:', error);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  useEffect(() => {
    if (drawerData?.show && drawerData?.data) {
      fetchUserData(drawerData?.data?.id);
    }
  }, [drawerData]);
  const handleClose = () => {
    form.resetFields();
    setManagerOptions([]);
    onClose();
  };

  return (
    <Drawer
      title='Update Reimbursement Details'
      width={420}
      onClose={handleClose}
      visible={drawerData?.show}
      footer={
        <Row gutter={[16, 16]} justify='end'>
          <Col>
            <Button onClick={handleClose} disabled={isUpdating}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type='primary' onClick={handleUpdate} loading={isUpdating}>
              Update
            </Button>
          </Col>
        </Row>
      }
      closable={false}
      maskClosable={false}
    >
      <Spin spinning={fetchingUserDetais} tip='Fetching reimbursement details...'>
        {' '}
        <Form form={form} layout='vertical'>
          <Row>
            <Col span={24} className='mb-16'>
              <Typography.Text
                type='secondary'
                strong
              >{`${drawerData?.data?.user?.first_name} ${drawerData?.data?.user?.last_name} (${drawerData?.data?.user?.username})`}</Typography.Text>
            </Col>
            <Col span={24}>
              <Form.Item
                name='city'
                label='City'
                rules={[{ required: true, message: 'Please select city' }]}
              >
                <Select
                  placeholder='Select City'
                  showSearch
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {cityOptions?.map((city) => (
                    <Option key={city?.id} value={city?.id}>
                      {city?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='department'
                label='Department'
                rules={[{ required: true, message: 'Please select department' }]}
              >
                <Select
                  placeholder='Select Department'
                  showSearch
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {departmentOptions?.map((department) => (
                    <Option key={department?.id} value={department?.id}>
                      {department?.department_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='cadre'
                label='Cadre'
                rules={[{ required: true, message: 'Please select cadre' }]}
              >
                <Select
                  placeholder='Select Cadre'
                  showSearch
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {cadreOptions?.map((cadre) => (
                    <Option key={cadre?.id} value={cadre?.id}>
                      {cadre?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='manager'
                label='Manager'
                rules={[{ required: true, message: 'Please select manager' }]}
              >
                <Select
                  showSearch
                  placeholder='Search for manager'
                  notFoundContent={fetching ? 'Loading...' : null}
                  filterOption={false} // disable default filtering
                  onSearch={fetchManagers}
                  loading={fetching}
                  allowClear
                  onChange={(value, manager) => {
                    setSelectedManager({ ...manager });
                  }}
                >
                  {managerOptions.map((manager) => (
                    <Option key={manager.id} value={manager.id} erp_id={manager?.erp_id}>
                      {`${manager?.name}(${manager?.erp_id})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default UpdateUserDrawer;
