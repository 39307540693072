import Layout from 'containers/Layout';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Breadcrumb, message, Spin, Empty, Button } from 'antd';
import axiosInstance from 'config/axios';
import VlcReportCardPdf from '../viewReportCard/vlcReportPrint';
import SportsReportCard from '../viewReportCard/sportReportPrint/SportsReportCard';
import { AlertNotificationContext } from 'context-api/alert-context/alert-state';
import { X_DTS_HOST } from 'v2/reportApiCustomHost';
import axios from 'axios';
import endpoints from 'config/endpoints';
import endpointsV2 from 'v2/config/endpoints';
import apiRequest from 'containers/dashboard/StudentDashboard/config/apiRequest';
import { generateQueryParamSting } from 'utility-functions';
import AssesmentReportNew from '../../assesment-report-card/newReportPrint';
import ReportCardNewBack from '../../assesment-report-card/reportCardNewBack';
import PhysicalEducationReportCard from '../../assesment-report-card/physicalEducationReportCard/physicalEducationReportCard';
import { Box } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import TabPanel from '../../../../components/tab-panel';

const { TabPane } = Tabs;

const ViewReports = () => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [filterData, setFilterData] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const { setAlert } = useContext(AlertNotificationContext);
  const { token } = JSON.parse(localStorage.getItem('userDetails')) || {};
  const [pdfUrl, setPdfUrl] = useState(null);
  const [sportsPdfUrl, setSportsPdfUrl] = useState(null);
  const [reportCardDataNew, setReportCardDataNew] = useState(null);
  const [PEReportCardData, setPEReportCardData] = useState(null);
  const [showPEConfig, setShowPEConfig] = useState([]);
  const [principalSignData, setPrincipalSignData] = useState([]);
  const componentRef = useRef();
  const [sportsPdfBlob, setSportsPdfBlob] = useState(null);
  const [vlcPdfBlob, setVlcPdfBlob] = useState(null);
  const [activeTabParent, setActiveTabParent] = useState('0');
  useEffect(() => {
    if (location.state) {
      const { filterData: passedFilterData, studentData: passedStudentData } =
        location.state;
      setFilterData(passedFilterData);
      setStudentData(passedStudentData);
    }
  }, [location.state]);

  useEffect(() => {
    return () => {
      if (pdfUrl) URL.revokeObjectURL(pdfUrl);
      if (sportsPdfUrl) URL.revokeObjectURL(sportsPdfUrl);
    };
  }, [pdfUrl, sportsPdfUrl]);

  useEffect(() => {
    fetchPrincipalSignature({
      branch_id: location.state?.filterData?.branch?.branch?.id,
      session_year: location.state?.filterData?.branch?.session_year?.id,
      user_level: 8,
    });
  }, []);

  useEffect(() => {
    if (studentData && filterData) {
      switch (activeTabParent) {
        case '0':
          fetchNewReportCardData();
          break;
        case '1':
          fetchVlcReportData();
          break;
        case '2':
          fetchSportsReportData();
          break;
      }
    }
  }, [activeTabParent, studentData, filterData]);

  const fetchPrincipalSignature = (params = {}) => {
    axiosInstance
      .get(`${endpointsV2.principalSignAcad}`, {
        params: params,
      })
      .then((res) => {
        setPrincipalSignData(res.data?.result.results);
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {});
  };

  const fetchNewReportCardData = () => {
    setIsLoading(true);
    let paramObj = {
      acad_session_id: filterData.branch?.id,
      erp_id: studentData.erp_id,
      grade_id: filterData.grade?.grade_id,
      section_id: filterData.section?.section_id,
    };
    let params = `?${generateQueryParamSting({ ...paramObj })}`;
    apiRequest(
      'get',
      `${endpoints.assessmentReportTypes.reportCardDataNew}${params}`,
      null,
      null,
      false,
      10000
    )
      .then((result) => {
        if (result) {
          setReportCardDataNew(result.data.result);
          // const PEParams = {
          //   branch_id: filterData?.branch?.branch?.id,
          //   grade_id: filterData?.grade?.grade_id,
          //   erp_id: studentData.erp_id,
          // };
          // fetchPEReportCardData(PEParams);
        }
      })
      .catch((error) => {
        setAlert(
          'error',
          error?.response?.data?.message || 'Error while fetching Report card'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchVlcReportData = () => {
    setIsLoading(true);
    axiosInstance
      .get(`${endpointsV2.assessmentReportConfig.getVlcReport}`, {
        params: {
          erp_id: studentData.id,
          section_mapping: filterData?.section?.id,
          term_id: 1,
        },
      })
      .then(async (res) => {
        if (res.data && res.data.result) {
          const { blob, url } = await VlcReportCardPdf(
            res.data.result,
            principalSignData
          );
          setPdfUrl(url);
          setVlcPdfBlob(blob);
        }
      })
      .catch((error) => {
        console.error('Error fetching VLC report:', error);
        message.error(error?.response?.data?.message ?? 'Error fetching VLC report');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchSportsReportData = () => {
    setIsLoading(true);
    axiosInstance
      .get(`${endpointsV2.assessmentReportConfig.getSportsReport}`, {
        params: {
          erp_id: studentData.id,
          section_mapping: filterData?.section?.id,
          term_id: 1,
        },
      })
      .then((res) => {
        const { blob, url } = SportsReportCard(res.data.result, principalSignData);
        setSportsPdfUrl(url);
        setSportsPdfBlob(blob);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message ?? 'Error fetching Sports report');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDownload = (title, pdfBlob) => {
    if (pdfBlob) {
      const fileName = `${title}-${studentData?.user?.first_name}-${studentData.erp_id}.pdf`;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  const renderReportCardNew = (componentRef) => {
    switch (activeTab) {
      case 0:
        return (
          <AssesmentReportNew
            principalSignData={principalSignData}
            reportCardDataNew={reportCardDataNew}
            ref={componentRef}
          />
        );
      case 1:
        return (
          <ReportCardNewBack
            principalSignData={principalSignData}
            reportCardDataNew={reportCardDataNew}
            ref={componentRef}
          />
        );
      case 2:
        return (
          <PhysicalEducationReportCard
            principalSignData={principalSignData}
            peReportCardData={PEReportCardData}
            ref={componentRef}
          />
        );
    }
  };

  const renderPdfPreview = (url, title, pdfBlob) => {
    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px',
          }}
        >
          <Spin size='large' />
        </div>
      );
    }
    if (!url) {
      return (
        <Empty className='mb-4' description={`No ${title} report card to preview`} />
      );
    }
    return (
      <div>
        <div className='text-right'>
          <Button
            onClick={() => handleDownload(title, pdfBlob)}
            style={{ marginBottom: '10px' }}
          >
            Download {title} Report
          </Button>
        </div>
        <iframe
          src={url}
          style={{ width: '100%', height: '500px', border: 'none' }}
          title={`${title} Report PDF`}
        />
      </div>
    );
  };

  return (
    <Layout>
      <div className='row pt-3'>
        <div className='col-md-6 th-bg-grey' style={{ zIndex: 2 }}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              className='th-black-1 th-16 th-grey'
              href='/assessment-reports'
            >
              Assessment
            </Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>Reports</Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>View Reports</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-md-12'>
          <div className='th-tabs th-bg-white'>
            <Tabs type='card' onChange={setActiveTabParent} activeKey={activeTabParent}>
              <TabPane tab='Report Card' key='0'>
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '500px',
                    }}
                  >
                    <Spin size='large' />
                  </div>
                ) : reportCardDataNew ? (
                  <>
                    <Box style={{ margin: '20px auto', width: '95%' }}>
                      <div className='row justify-content-between'>
                        <TabPanel
                          tabValue={activeTab}
                          setTabValue={setActiveTab}
                          tabValues={
                            showPEConfig?.includes(String(filterData?.grade?.grade_id))
                              ? ['Front', 'Back', 'Physical Education']
                              : ['Front', 'Back']
                          }
                        />
                        {activeTab != '2' ? (
                          <ReactToPrint
                            trigger={() => (
                              <Button type='primary' style={{ fontSize: 15 }}>
                                Download Report Card
                              </Button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={`Eduvate ${
                              activeTab == '0'
                                ? 'Front'
                                : activeTab == '1'
                                ? 'Back'
                                : 'PhysicalEducationReportCard'
                            } - ${reportCardDataNew?.user_info?.name}`}
                          />
                        ) : (
                          activeTab == '2' &&
                          PEReportCardData?.data
                            ?.map((item) => item?.criteria_title)
                            .flat()?.length > 0 && (
                            <ReactToPrint
                              trigger={() => (
                                <Button type='primary' style={{ fontSize: 15 }}>
                                  Download Report Card
                                </Button>
                              )}
                              content={() => componentRef.current}
                              documentTitle={`Eduvate PhysicalEducationReportCard - ${reportCardDataNew?.user_info?.name}`}
                            />
                          )
                        )}
                      </div>
                    </Box>
                    <Box style={{ margin: '20px auto', width: '95%' }}>
                      {renderReportCardNew(componentRef)}
                    </Box>
                  </>
                ) : (
                  <Empty className='mb-4' description='No Report Card to preview' />
                )}
              </TabPane>
              <TabPane tab='Arts & SOCH' key='1'>
                {renderPdfPreview(pdfUrl, 'Arts & SOCH', vlcPdfBlob)}
              </TabPane>
              <TabPane tab='Sports' key='2'>
                {renderPdfPreview(sportsPdfUrl, 'Sports', sportsPdfBlob)}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewReports;
